<template>
  <div class="app">
    <iframe :src="pdf" width="100%" height="100%"></iframe>
  </div>
</template>

<script>
export default {
  name: "Down",
  data() {
    return {
      pdf: "",
    };
  },
  created() {
    this.pdf = this.$route.query.pdf;
  },
};
</script>

<style scoped>
.app {
  width: 100vw;
  height: 100vh;
}
</style>
